import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import Layout from '../components/Layout'
import Divider from '../components/Divider'
import HorizontalDivider from '../components/HorizontalDivider'
import VennDiagramMobile from '../components/VennDiagramMobile'
import VennDiagramTablet from '../components/VennDiagramTablet'
import About from '../components/About'
import Process from '../components/Process'
import OurServices from '../components/OurServices'
import Button from '../components/Button'
import FullWidthImage from '../components/FullWidthImage'

import Content from '../components/Content'
import MarkdownContent from '../components/MarkdownContent'
import Helmet from 'react-helmet'

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  mainpitch,
  firstContent,
  firstInformation,
  secondInformation,
  secondContent,
  contentComponent,
  siteTitle,
}) => {
  const PageContent = contentComponent || Content
  const heroImage = getImage(image) || image

  return (
    <div
      className="top-[-90px] mb-[-90px] md:top-[-140px] md:mb-[-140px]"
      style={{ position: 'relative' }}
    >
      <Helmet>
        <title>{`Home | ${siteTitle || 'Content Manager'}`}</title>
        <meta
          name="description"
          content={`${mainpitch.title} ${mainpitch.description}`}
        />
        <meta property="og:title" content={`Home | ${siteTitle}`} />
        <meta
          property="og:description"
          content={`${mainpitch.title} ${mainpitch.description}`}
        />
      </Helmet>
      <FullWidthImage img={heroImage} />
      <section>
        <div className="container">
          <div className="flex justify-center flex-col">
            <div className="text-center mt-8">
              <h1 className="title font-bold md:text-3xl md:hidden">
                {mainpitch.title}
              </h1>
              <h3 className="md:hidden">{mainpitch.description}</h3>
              {/* Tablet */}
              <h1 className="hidden title font-semibold md:text-[34px] md:block md:leading-relaxed lg:text-5xl lg:leading-[150%] lg:mt-8 lg:w-[70%] lg:m-auto">
                {mainpitch.title}
                <br />
                {mainpitch.description}
              </h1>
            </div>
            <Divider />
            <div className="columns">
              <div className="text-center">
                <h3>{firstContent.title}</h3>
                <p className="md:px-8 lg:px-20">{firstContent.description}</p>
              </div>
            </div>
            <Divider />
            <div className="text-center">
              <h3>{secondContent.title}</h3>
              <p className="md:px-8 lg:px-20">
                {secondContent.firstDescription}
              </p>
            </div>
            <Divider />
            <div className="text-center mb-8">
              <p className="md:px-8 lg:px-20">
                {secondContent.secondDescription}
              </p>
            </div>
            <Link
              className="w-fit self-center md:mb-8 md:mt-4"
              to="/contact"
            >
              <Button name="Get In Touch" />
            </Link>
            <HorizontalDivider />
            <div className="text-left mb-4 lg:flex">
              <h2 className="mb-8 md:w-1/3 md:leading-tight md:mt-4 lg:ml-20 xl:w-[30%]">
                {firstInformation.title}
              </h2>
              <div className="lg:flex lg:flex-col lg:items-start lg:flex-1 lg:mt-20 lg:pr-10 xl:pr-32">
                <PageContent
                  className="content text-left md:ml-32 lg:ml-8 md:leading-relaxed"
                  content={firstInformation.description}
                />
                <Link
                  className="hidden w-fit self-center mt-4 mb-0 md:self-start lg:ml-8 lg:block lg:mt-10 lg:mb-4"
                  to="/process"
                >
                  <Button name="The Joinery Process" />
                </Link>
              </div>
            </div>
            <Link
              className="w-fit self-center mt-4 mb-0 md:self-start md:ml-32 md:my-8 lg:hidden"
              to="/process"
            >
              <Button name="Process" />
            </Link>
            <HorizontalDivider />
            <div className="text-left mb-4 md:mb-10">
              <h2 className="mb-8 md:mt-8 lg:ml-20 lg:mb-20">
                {secondInformation.title}
              </h2>
              <p className="md:text-xl md:leading-[150%] lg:ml-20 lg:pr-10 xl:pr-32">
                {secondInformation.description}
              </p>
            </div>
            <div className="md:hidden">
              <VennDiagramMobile />
            </div>
            <div className="hidden md:block">
              <VennDiagramTablet />
            </div>
            <div className="mt-8 flex justify-center">
              <Link className="w-fit self-center md:my-8" to="/about">
                <Button name="About" />
              </Link>
            </div>
            <HorizontalDivider />
            <div className="flex items-end justify-between md:mb-2 md:mt-2 lg:ml-20 lg:mr-20 lg:pt-10 lg:pb-10">
              <Link to="/services">
                <div className="flex flex-col justify-between">
                  <OurServices />
                  <h4 className="text-center mt-2 md:mt-4 lg:text-3xl">
                    Services
                  </h4>
                </div>
              </Link>
              <Link to="/process">
                <div className="flex flex-col justify-between">
                  <Process />
                  <h4 className="text-center mt-2 md:mt-4 lg:text-3xl">
                    Process
                  </h4>
                </div>
              </Link>
              <Link to="/about">
                <div className="flex flex-col justify-between">
                  <About />
                  <h4 className="text-center mt-2 md:mt-4 lg:text-3xl">
                    About
                  </h4>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  mainpitch: PropTypes.object,
  firstContent: PropTypes.object,
  firstInformation: PropTypes.object,
  secondInformation: PropTypes.object,
  secondContent: PropTypes.object,
  contentComponent: PropTypes.func,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        mainpitch={frontmatter.mainpitch}
        firstContent={frontmatter.firstContent}
        firstInformation={frontmatter.firstInformation}
        secondInformation={frontmatter.secondInformation}
        secondContent={frontmatter.secondContent}
        contentComponent={MarkdownContent}
        siteTitle={data.site.siteMetadata.title}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        mainpitch {
          title
          description
        }
        firstContent {
          title
          description
        }
        firstInformation {
          title
          description
        }
        secondInformation {
          title
          description
        }
        secondContent {
          title
          firstDescription
          secondDescription
        }
      }
    }
  }
`
