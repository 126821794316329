import * as React from 'react'
import process from '../img/process.svg'

const Process = () => (
  <img
    className="m-auto w-[71px] h-[74px] md:w-[140px] md:h-[140px] md:mb-[0px]"
    src={process}
    alt="process"
  />
)

export default Process
