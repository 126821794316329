import * as React from 'react'
import diagram from '../img/venn-diagram-tablet.svg'

const VennDiagramTablet = () => (
  <img
    className="m-auto"
    src={diagram}
    alt="venn-diagram-tablet"
    width="700"
    height="497"
  />
)

export default VennDiagramTablet
