import * as React from 'react'
import ourServices from '../img/our-services.svg'

const OurServices = () => (
  <img
    className="m-auto w-[71px] h-[74px] md:w-[140px] md:h-[140px] md:mb-[0px]"
    src={ourServices}
    alt="our-services-mobile"
  />
)

export default OurServices
