import * as React from 'react'
import about from '../img/about.svg'

const About = () => (
  <img
    className="m-auto w-[71px] h-[74px] md:w-[140px] md:h-[140px] md:mb-[0px]"
    src={about}
    alt="about"
  />
)

export default About
