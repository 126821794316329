import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function FullWidthImage(props) {
  const { img, imgPosition = 'center' } = props

  return (
    <React.Fragment>
      <div
        className="margin-top-0 h-400 min-h-[400px] md:h-600 md:min-h-[600px] lg:h-800 lg:min-h-[800px]"
        style={{
          display: 'grid',
          alignItems: 'center',
        }}
      >
        {img?.url ? (
          <img
            src={img}
            objectFit={'cover'}
            objectPosition={imgPosition}
            className="h-400 min-h-[400px] md:h-600 md:min-h-[600px] lg:h-800 lg:min-h-[800px]"
            style={{
              gridArea: '1/1',
              // You can set a maximum height for the image, if you wish.
              // height: height,
              // minHeight: height,
              width: '100%',
            }}
            // You can optionally force an aspect ratio for the generated image
            aspectratio={3 / 1}
            // This is a presentational image, so the alt should be an empty string
            alt=""
            formats={['auto', 'webp', 'avif']}
          />
        ) : (
          <GatsbyImage
            image={img}
            objectFit={'cover'}
            objectPosition={imgPosition}
            className="h-400 min-h-[400px] max-h-[400px] md:h-600 md:min-h-[600px] md:max-h-[600px] lg:h-800 lg:min-h-[800px] lg:max-h-[800px]"
            style={{
              gridArea: '1/1',
              // You can set a maximum height for the image, if you wish.
            }}
            layout="fullWidth"
            // You can optionally force an aspect ratio for the generated image
            aspectratio={3 / 1}
            // This is a presentational image, so the alt should be an empty string
            alt=""
            formats={['auto', 'webp', 'avif']}
          />
        )}
        <div
          style={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: '1/1',
            position: 'relative',
            // This centers the other elements inside the hero component
            placeItems: 'center',
            display: 'grid',
          }}
        >
          {/* Any content here will be centered in the component */}
        </div>
      </div>
    </React.Fragment>
  )
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  height: PropTypes.number,
}
