import * as React from 'react'
import diagram from '../img/venn-diagram-mobile.svg'

const VennDiagramMobile = () => (
  <img
    className="m-auto"
    src={diagram}
    alt="venn-diagram-mobile"
    width="307"
    height="516"
  />
)

export default VennDiagramMobile
