import * as React from 'react'
import divider from '../img/divider-mobile.svg'

const Divider = () => (
  <img
    className="m-auto my-2 h-9 md:h-14 md:w-[5.4px] lg:h-24 lg:w-[8.4px]"
    src={divider}
    alt="divider"
    width="4"
    height="37"
  />
)

export default Divider
